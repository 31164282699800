.loader_auth{
    height: 60px;
    background: white;
}
.loader_auth{
  height: 60px;
  background: white;
}
.lds-ring-auth div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  
  border: 2px solid #B9A0CE;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #B9A0CE transparent transparent transparent;

  margin: 0;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.lds-ring-auth div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-auth div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-auth div:nth-child(3) {
  animation-delay: -0.15s;
}

.lds-ring_insta div:nth-child(1) {
animation-delay: -0.45s;
}

.lds-ring_insta div:nth-child(2) {
animation-delay: -0.3s;
}

.lds-ring_insta div:nth-child(3) {
animation-delay: -0.15s;
}
.lds-ring_insta div {
box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 6px;
  border: 3px solid white;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--main) transparent transparent transparent;

}
.lds-ring-btn div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    
    border: 2px solid var(--main);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--main) transparent transparent transparent;

    margin: 0;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.lds-ring-btn div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring-btn div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring-btn div:nth-child(3) {
    animation-delay: -0.15s;
}
.lds-ring-btn_insta div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-btn_insta div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-btn_insta div:nth-child(3) {
  animation-delay: -0.15s;
}
.lds-ring-btn_insta div {
  box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 6px;
    border: 3px solid white;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--main) transparent transparent transparent;

}


.lds-ring-search div {
  box-sizing: border-box;
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #96C6F0;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #96C6F0 transparent transparent transparent;
  margin: 0;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 8px;
}

.lds-ring-search div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-search div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-search div:nth-child(3) {
  animation-delay: -0.15s;
}
.position_search_text{
  text-align: center;
}